import { forwardRef, MouseEvent, ReactNode, useState } from "react";

import { CalendarSize } from "@sellernote/shared/src/headlessComponents/useCalendar";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import newId from "@sellernote/shared/src/utils/common/newId";
import CalendarOutlinedIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/CalendarOutlinedIcon";

import { COLOR } from "../../../styles/colors";

import XMarkCircleIcon from "../../svgIcons/XMarkCircleIcon";
import Styled from "./index.styles";

function CustomInput(
  {
    date,
    onClick,
    placeholder,
    size = "default",
    isDisabled,
    labelInfo,
    isEditMode,
    inputWidth,
    dateFormat,
    allowsLocalReset,
    onCalendarReset,
  }: {
    /**
     * onClick, placeholder 는 react-datepicker 라이브러리에서 주입되는 값
     */
    onClick?: () => void;
    placeholder?: string;

    date: string;
    size?: CalendarSize;
    labelInfo: {
      label: ReactNode;
      isLabelHidden?: boolean;
    };
    isDisabled?: boolean;
    inputWidth?: string;
    isEditMode: boolean;
    dateFormat: "YY_MM_DD" | "YY_MM_DD_HH_mm";
    allowsLocalReset: boolean;
    onCalendarReset: () => void;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  // label id를 unique하게 사용하기 위함
  const [inputId] = useState(() => newId("input-date-picker-id-"));

  const handleCalendarReset = (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();

    onCalendarReset();
  };

  return (
    <Styled.customInput
      size={size}
      isHorizontal={size === "small"}
      isDisabled={isDisabled}
      ref={ref}
      onClick={onClick}
    >
      <label
        htmlFor={inputId}
        className={`${(labelInfo.isLabelHidden && "visually-hidden") ?? ""}`}
      >
        {labelInfo.label}
      </label>

      <Styled.inputContainer
        borderColor={(() => {
          if (isDisabled) {
            return COLOR.grayScale_300;
          }

          if (isEditMode) {
            return COLOR.primary_900;
          }

          return COLOR.grayScale_400;
        })()}
        size={size}
        isDisabled={isDisabled}
        inputWidth={inputWidth}
      >
        <input
          type="text"
          value={formatDate({ date, type: dateFormat })}
          placeholder={placeholder || ""}
          readOnly
        />

        {date && allowsLocalReset && (
          <XMarkCircleIcon
            width={16}
            height={16}
            color={COLOR.grayScale_400}
            onClick={handleCalendarReset}
          />
        )}

        <CalendarOutlinedIcon
          width={16}
          height={16}
          color={isEditMode ? COLOR.primary_900 : COLOR.grayScale_300}
        />
      </Styled.inputContainer>
    </Styled.customInput>
  );
}

export default forwardRef(CustomInput);
