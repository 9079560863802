import styled from "styled-components";

import { CalendarSize } from "@sellernote/shared/src/headlessComponents/useCalendar";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

const calendarWrapper = styled.div`
  .react-datepicker-popper {
    padding: 0;
    z-index: 10;
  }
`;

const container = styled.div`
  padding: 16px 16px 8px;
  display: flex;

  border-radius: 2px;
  background-color: ${COLOR.wh};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  .react-datepicker {
    border: 0;
  }

  .react-datepicker__header,
  .react-datepicker__time-container {
    background-color: ${COLOR.wh};
    border: 0;
  }

  .react-datepicker__header {
    padding: 0;

    .react-datepicker__day-names {
      margin: 0;

      .react-datepicker__day-name:first-child {
        color: ${COLOR.point_400};
      }

      .react-datepicker__day-name:not(:first-of-type) {
        margin-left: 1px;
      }

      .react-datepicker__day-name {
        margin: 0;
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        ${setFontStyle("Body3")}
        color: ${TEXT_COLOR.black_2};
      }
    }
  }

  .react-datepicker__month {
    margin: 0;

    .react-datepicker__week {
      .react-datepicker__day:not(:first-of-type) {
        margin-left: 1px;
      }

      &:not(:last-child) {
        margin-bottom: 1px;
      }
    }

    .react-datepicker__week {
      .react-datepicker__day {
        margin: 0;
        width: 36px;
        height: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        ${setFontStyle("Body3")}
        color: ${TEXT_COLOR.black_2};
        background-color: ${COLOR.wh};

        &:focus-visible {
          outline: 0;
        }

        &:hover {
          border-radius: 60px;
          background-color: ${COLOR.primary_200};
          color: ${TEXT_COLOR.white_1};
        }
      }

      .react-datepicker__day.sunday,
      .react-datepicker__day.holiday {
        color: ${COLOR.point_400};

        &:hover {
          color: ${TEXT_COLOR.white_1};
        }
      }

      .react-datepicker__day--today {
        border-radius: 60px;
        background-color: ${COLOR.grayScale_100};
        color: ${TEXT_COLOR.black_2};
      }

      .react-datepicker__day--selected,
      .react-datepicker__day--selected.sunday,
      .react-datepicker__day--selected.holiday,
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--keyboard-selected.sunday,
      .react-datepicker__day--keyboard-selected.holiday {
        border-radius: 60px;
        background-color: ${COLOR.primary_600};
        color: ${TEXT_COLOR.white_1};
      }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__day--keyboard-selected.sunday,
      .react-datepicker__day--keyboard-selected.holiday {
        background-color: ${COLOR.wh};
        color: ${TEXT_COLOR.black_2};
      }

      .react-datepicker__day--disabled {
        color: ${COLOR.grayScale_400};
        cursor: not-allowed;
      }
      .react-datepicker__day--disabled.sunday,
      .react-datepicker__day--disabled.holiday {
        color: ${COLOR.point_200};
      }
    }
  }
`;

const customHeader = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .selected-month {
    ${setFontStyle("SubHead2")};
    color: ${TEXT_COLOR.black_2};
  }
`;

const timePickerWithDivide = styled.div`
  display: flex;

  .divide {
    margin: 0 30px;
  }
`;

const timePicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > .select-option-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  > .button {
    align-self: flex-end;
  }
`;

const customInput = styled.div<{
  isDisabled?: boolean;
  size: CalendarSize;
  isHorizontal?: boolean;
}>`
  display: flex;
  flex-direction: ${({ isHorizontal }) => (isHorizontal ? "row" : "column")};
  align-items: ${({ isHorizontal }) => (isHorizontal ? "center" : "initial")};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

  > label {
    color: ${({ size }) =>
      size === "small" ? TEXT_COLOR.black_3 : TEXT_COLOR.black_2};
    min-width: ${({ isHorizontal }) => (isHorizontal ? "50px" : "initial")};
    margin-bottom: ${({ isHorizontal }) => (isHorizontal ? "initial" : "4px")};
    margin-right: ${({ isHorizontal }) => (isHorizontal ? "8px" : "initial")};
    ${setFontStyle("Body3")};
  }
`;

const inputContainer = styled.div<{
  borderColor: string;
  size: CalendarSize;
  isDisabled?: boolean;
  inputWidth?: string;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ size }) => (size === "small" ? "11px 12px" : "12px 8px")};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 2px;
  height: ${({ size }) => (size === "small" ? "40px" : "48px")};
  width: ${({ inputWidth }) => (inputWidth ? inputWidth : "100%")};

  background-color: ${({ isDisabled }) =>
    isDisabled ? COLOR.grayScale_100 : COLOR.wh};

  > input {
    padding: 0;
    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
    color: ${({ isDisabled }) =>
      isDisabled ? TEXT_COLOR.black_disabled : TEXT_COLOR.black_2};
    background-color: ${({ isDisabled }) =>
      isDisabled ? COLOR.grayScale_100 : COLOR.wh};
    width: 100%;
    ${({ size }) =>
      size === "small" ? setFontStyle("Body4") : setFontStyle("Body2")};

    &::placeholder {
      color: ${({ isDisabled }) =>
        isDisabled ? TEXT_COLOR.black_disabled : TEXT_COLOR.black_4};
    }
  }

  > svg {
    flex: 0 0 auto;
  }
`;

export default {
  calendarWrapper,
  container,
  customHeader,
  timePickerWithDivide,
  timePicker,
  customInput,
  inputContainer,
};
