import { Dispatch, RefObject, SetStateAction, useState } from "react";
import DatePicker from "react-datepicker";

import { InputSelectOption } from "@sellernote/shared/src/headlessComponents/input/useInputSelect";
import {
  MinDateOption,
  SelectableTime,
  SelectedTime,
} from "@sellernote/shared/src/headlessComponents/useCalendar";
import { isSameDay } from "@sellernote/shared/src/utils/common/date";

import { COLOR } from "../../../styles/colors";

import Button from "../../button/Button";
import Divide from "../../Divide";
import SelectOption from "../../form/SelectOption";
import Styled from "./index.styles";

const getCurrentDateAndTime = () => {
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinute = currentDate.getMinutes();

  return { currentDate, currentHour, currentMinute };
};

const checkNeedsExcludingPastTime = ({
  minDate,
  currentDate,
  selectedDate,
}: {
  minDate: MinDateOption | undefined;
  currentDate: Date;
  selectedDate: Date | null;
}) => {
  const isSelectedDateToday = isSameDay(currentDate, selectedDate);

  return minDate === "today" && isSelectedDateToday;
};

const getDefaultHourOptionList = (
  selectableTime: SelectableTime | undefined = { startHour: 9, endHour: 18 }
) => {
  const { startHour, endHour, disabledHourList } = selectableTime;

  return Array.from({ length: endHour - startHour + 1 }, (v, i) => ({
    label: `${i + startHour}`.padStart(2, "0"),
    value: i + startHour,
  })).filter(({ value }) => !disabledHourList?.includes(value));
};

const getOptionListExcludingPastHour = (
  selectableTime: SelectableTime | undefined = { startHour: 9, endHour: 18 }
) => {
  const { currentHour, currentMinute } = getCurrentDateAndTime();

  const canSelectCurrentHour = currentMinute < 50;
  /**
   * 50분이 지나면 선택할 수 있는 '분' 옵션이 없기 떄문에 다음 시각부터 선택 가능
   */
  if (!canSelectCurrentHour) {
    return getDefaultHourOptionList(selectableTime).filter(
      ({ value }) => value > currentHour
    );
  }

  return getDefaultHourOptionList(selectableTime).filter(
    ({ value }) => value >= currentHour
  );
};

const getDefaultMinuteOptionList = () =>
  Array.from({ length: 6 }, (v, i) => ({
    label: `${i * 10}`.padStart(2, "0"),
    value: i * 10,
  }));

const getZeroMinuteOptionList = () => [{ label: "00", value: 0 }];

const getOptionListExcludingPastMinute = () => {
  const { currentMinute } = getCurrentDateAndTime();

  const canSelectDefaultOptionList = currentMinute >= 50;
  /**
   * 50분이 지나면 다음 시각부터 선택 가능하기 때문에 모든 '분' 옵션을 선택 가능
   */
  if (canSelectDefaultOptionList) {
    return getDefaultMinuteOptionList();
  }

  return getDefaultMinuteOptionList().filter(
    ({ value }) => value > currentMinute
  );
};

export const getDateWithTime = (date: Date | null, time: SelectedTime) => {
  if (!date) {
    return "";
  }

  const { hour, minute } = time;

  if (hour === null || minute === null) {
    return "";
  }

  const selectedDate = new Date(date);

  selectedDate.setHours(hour);
  selectedDate.setMinutes(minute);

  return selectedDate.toISOString();
};

export const getHourOptionList = ({
  selectedDate,
  minDate,
  selectableTime,
}: {
  selectedDate: Date | null;
  selectableTime?: SelectableTime;
  minDate: MinDateOption | undefined;
}): InputSelectOption<number>[] => {
  const { currentDate } = getCurrentDateAndTime();

  const needsExcludingPastTime = checkNeedsExcludingPastTime({
    minDate,
    currentDate,
    selectedDate,
  });
  if (needsExcludingPastTime) {
    return getOptionListExcludingPastHour(selectableTime);
  }

  return getDefaultHourOptionList();
};

export const getMinuteOptionList = ({
  selectedDate,
  selectedTime,
  minDate,
  selectableTime,
}: {
  selectedDate: Date | null;
  selectedTime: SelectedTime;
  selectableTime?: SelectableTime;
  minDate: MinDateOption | undefined;
}): InputSelectOption<number>[] => {
  const isEndHourSelected = selectedTime.hour === selectableTime?.endHour;
  if (isEndHourSelected) {
    return getZeroMinuteOptionList();
  }

  const { currentDate, currentHour } = getCurrentDateAndTime();

  const needsExcludingPastTime = checkNeedsExcludingPastTime({
    minDate,
    currentDate,
    selectedDate,
  });
  const isCurrentHourSelected = currentHour === selectedTime.hour;
  const needsToExcludePastMinute =
    needsExcludingPastTime && isCurrentHourSelected;
  if (needsToExcludePastMinute) {
    return getOptionListExcludingPastMinute();
  }

  return getDefaultMinuteOptionList();
};

export const getTimeToReset =
  ({
    selectedDate,
    selectedTime,
    selectableTime,
    minDate,
  }: {
    selectedDate: Date | null;
    selectedTime: SelectedTime;
    selectableTime?: SelectableTime | undefined;
    minDate: MinDateOption | undefined;
  }) =>
  (type: "hour" | "minute") =>
  (prevTime: number | null) => {
    return checkNeedsToResetTime({
      selectedDate,
      selectedTime,
      selectableTime,
      minDate,
    })(type)
      ? null
      : prevTime;
  };

export const checkNeedsToResetTime =
  ({
    selectedDate,
    selectedTime,
    selectableTime,
    minDate,
  }: {
    selectedDate: Date | null;
    selectedTime: SelectedTime;
    selectableTime?: SelectableTime | undefined;
    minDate: MinDateOption | undefined;
  }) =>
  (type: "hour" | "minute") => {
    const optionList = (() => {
      if (type === "hour") {
        return getHourOptionList({
          selectedDate,
          selectableTime,
          minDate,
        });
      }

      if (type === "minute") {
        return getMinuteOptionList({
          selectedDate,
          selectedTime,
          selectableTime,
          minDate,
        });
      }

      return [];
    })();

    const hasSelectedTime = selectedTime[type] !== null;
    const isInOptionList = optionList.find(
      ({ value }) => value === selectedTime[type]
    );

    return hasSelectedTime && !isInOptionList;
  };

export default function TimePicker({
  datePickerRef,
  prevDate,
  setDate,
  selectedDate,
  selectedTime,
  setSelectedTime,
  minDate,
  selectableTime = { startHour: 9, endHour: 18 },
}: {
  datePickerRef: RefObject<DatePicker>;
  prevDate: string;
  setDate: (val: string) => void;
  selectedDate: Date | null;
  selectedTime: SelectedTime;
  setSelectedTime: Dispatch<SetStateAction<SelectedTime>>;
  minDate?: MinDateOption;
  selectableTime?: SelectableTime;
}) {
  const [hourOptionList, setHourOptionList] = useState(
    getHourOptionList({ selectableTime, minDate, selectedDate })
  );
  const [minuteOptionList, setMinuteOptionList] = useState(
    getMinuteOptionList({
      selectedDate,
      selectedTime,
      selectableTime,
      minDate,
    })
  );

  const handleHourSelect = (selectedHour: number | null) => {
    setSelectedTime((prev) => {
      const getMinuteToReset = getTimeToReset({
        selectedDate,
        selectedTime: {
          ...prev,
          hour: selectedHour,
        },
        selectableTime,
        minDate,
      })("minute");

      return {
        ...prev,
        hour: selectedHour,
        minute: getMinuteToReset(prev.minute),
      };
    });
  };

  const handleMinuteSelect = (selectedMinute: number | null) => {
    setSelectedTime((prev) => ({ ...prev, minute: selectedMinute }));
  };

  const handleComplete = () => {
    setDate(getDateWithTime(selectedDate, selectedTime));

    datePickerRef.current?.setOpen(false);
  };

  const canComplete = (() => {
    const isAllSelected =
      selectedDate !== null &&
      selectedTime.hour !== null &&
      selectedTime.minute !== null;

    if (!prevDate) {
      return isAllSelected;
    }

    /**
     * 수정하는 경우에는 이전에 선택한 값과 다른 경우에만 선택완료 버튼을 활성화
     */
    const selectedDateWithTime = getDateWithTime(selectedDate, selectedTime);
    const isChanged = prevDate !== selectedDateWithTime;
    return isChanged && isAllSelected;
  })();

  const handleHourOptionOpen = () => {
    setHourOptionList(
      getHourOptionList({ selectableTime, minDate, selectedDate })
    );
  };

  const handleMinuteOptionOpen = () => {
    setMinuteOptionList(
      getMinuteOptionList({
        selectedDate,
        selectedTime,
        selectableTime,
        minDate,
      })
    );
  };

  return (
    <Styled.timePickerWithDivide>
      <Divide
        type="vertical"
        height="100%"
        lineStyle="line"
        thickness={1}
        color={COLOR.grayScale_400}
      />
      <Styled.timePicker>
        <div className="select-option-container">
          <SelectOption
            size="default"
            width="150px"
            labelInfo={{ label: "시" }}
            options={hourOptionList}
            selectedOptionValue={selectedTime.hour}
            onSelect={handleHourSelect}
            onOpen={handleHourOptionOpen}
          />

          <SelectOption
            size="default"
            width="150px"
            labelInfo={{ label: "분" }}
            options={minuteOptionList}
            selectedOptionValue={selectedTime.minute}
            onSelect={handleMinuteSelect}
            onOpen={handleMinuteOptionOpen}
          />
        </div>

        <Button
          borderType="outlined"
          handleClick={handleComplete}
          label="선택완료"
          size="small"
          theme="secondary"
          disabled={!canComplete}
        />
      </Styled.timePicker>
    </Styled.timePickerWithDivide>
  );
}
